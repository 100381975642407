import { LoadingButton } from '@mui/lab'
import { Button, Stack } from '@mui/material'

const ActionButtons = ({
  confirmText = "Save",
  cancelText = "Cancel",
  handleCancel,
  handleConfirm,
  sx,
  fullWidth = true,
  buttonWidth = "150px",
  isLoading,
  disableConfirm = false,
}) => {
  return (
    <Stack direction="row" gap={1} p={1.5} alignItems="center" sx={sx} >
      <Button sx={{ minWidth: !fullWidth && buttonWidth }} variant='export' onClick={handleCancel} fullWidth={fullWidth}>
        {cancelText}
      </Button>
      <LoadingButton
        loading={isLoading}
        sx={{ minWidth: !fullWidth && buttonWidth }}
        variant='contained'
        type='submit'
        onClick={handleConfirm}
        fullWidth={fullWidth}
        disabled={disableConfirm}
      >
        {confirmText}
      </LoadingButton>
    </Stack >
  )
}

export default ActionButtons